@import '/src/assets/scss/define/fonts';
@import '/src/assets/scss/define/variables';

.custom-tooltip {
    font-size: $size-1;
    color: rgb(var(--app-whitish));
}
.mat-tooltip.custom-tooltip {
    border-radius: 20px;
    padding: 15px;
    box-shadow: var(--card-shadow);
}