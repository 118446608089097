.scrollable {
  overflow-y: auto;
  scrollbar-color: rgb(var(--app-primaryColor)) rgb(var(--app-whitish));
  overflow-x: hidden;
  scrollbar-width: thin;
}

.scrollable.light {
  overflow-y: auto;
  scrollbar-color: rgb(var(--app-whitish)) rgb(var(--app-primaryColor));
  overflow-x: hidden;
  scrollbar-width: thin;
}

.scrollable.dark {
  overflow-y: auto;
  scrollbar-color: rgb(var(--app-secondaryColor)) rgb(var(--app-primaryColor));
  overflow-x: hidden;
  scrollbar-width: thin;
}

.scrollable::-webkit-scrollbar {
  width: 7px;
}

.scrollable::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  border-radius: 4px;
  background-color: rgb(var(--app-lightGrey));
}

.scrollable.light::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  border-radius: 4px;
  background-color: rgb(var(--app-primaryColor));
}

.scrollable.dark::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  border-radius: 4px;
  background-color: rgb(var(--app-primaryColor));
}

.scrollable::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgb(var(--app-primaryColor));
}

.scrollable.light::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgb(var(--app-whitish));
}

.scrollable.dark::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgb(var(--app-secondaryColor));
}
