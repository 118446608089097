@import '/src/assets/scss/define/fonts';
@import '/src/assets/scss/define/variables';

.toast-container .ngx-toastr {
  border-radius: 12px;
  box-shadow: 15px 15px 10px rgba(0, 0, 0, 0.25);
  padding: 5px;
  background-position: 10px 10px;
  width: 350px;
  & .custom-notif {
    & .custom-notif-header {
      display: flex;
      align-items: center;
      padding: 5px;
      & span {
        display: none;
      }
    }
    & .custom-notif-body {
      background-color: rgba(var(--app-whitish), 0.8);
      color: rgb(var(--app-darkText));
      border-bottom-right-radius: 12px;
      border-bottom-left-radius: 12px;
      & .error-link {
        color: rgb(var(--app-primaryColor));
        &:hover {
        color: rgb(var(--app-primaryColor));
        }
      }
    }
  }
  &.new-version-toastr {
      color: rgb(var(--app-primaryColor));
      background-color: rgb(var(--app-lightGrey));
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDE4OSw0OSw4NiknIGQ9J00yNTYgOEMxMTkuMDQzIDggOCAxMTkuMDgzIDggMjU2YzAgMTM2Ljk5NyAxMTEuMDQzIDI0OCAyNDggMjQ4czI0OC0xMTEuMDAzIDI0OC0yNDhDNTA0IDExOS4wODMgMzkyLjk1NyA4IDI1NiA4em0wIDExMGMyMy4xOTYgMCA0MiAxOC44MDQgNDIgNDJzLTE4LjgwNCA0Mi00MiA0Mi00Mi0xOC44MDQtNDItNDIgMTguODA0LTQyIDQyLTQyem01NiAyNTRjMCA2LjYyNy01LjM3MyAxMi0xMiAxMmgtODhjLTYuNjI3IDAtMTItNS4zNzMtMTItMTJ2LTI0YzAtNi42MjcgNS4zNzMtMTIgMTItMTJoMTJ2LTY0aC0xMmMtNi42MjcgMC0xMi01LjM3My0xMi0xMnYtMjRjMC02LjYyNyA1LjM3My0xMiAxMi0xMmg2NGM2LjYyNyAwIDEyIDUuMzczIDEyIDEydjEwMGgxMmM2LjYyNyAwIDEyIDUuMzczIDEyIDEydjI0eicvPjwvc3ZnPg==");
      & .btn-close {
        background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat
      }
  }
  &.toast-success {
    & span.success-title {
      display: block;
    }
  }
  &.toast-error {
    & span.error-title {
      display: block;
    }
  }
  &.toast-warning {
    & span.warning-title {
      display: block;
    }
  }
  &.toast-info {
    & span.info-title {
      display: block;
    }
  }
}

.toast-success {
  background-color: #4f9062;
}

.toast-error {
  background-color: rgb(var(--app-thirdColor));
}

.toast-info {
  background-color: rgb(var(--app-secondaryColor));
  & .btn-close {
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23f1f3f4'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  }
}
