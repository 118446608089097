.form-control {
    display: flex;
    background: none;
    border: none;
    flex-direction: column;
    padding: unset;
    font-size: unset;
    font-weight: unset;
    color: unset;
    border-radius: unset;
    margin: 0 0 25px 0;
}

.is-form-invalid {
    transition: box-shadow 0.4s ease-in-out;
    box-shadow: 0px 0px 0px 2px rgb(var(--app-thirdColor)), var(--input-shadow)!important;
}
