ng-select.ng-select .ng-select-container  {            
  border-radius: 12px;
  border: solid 2px rgb(var(--app-lightGrey));
  color: rgb(var(--app-greyFont));
  background-color: rgb(var(--app-whitish));
}

ng-dropdown-panel {
  margin-top: 12px!important;
  border-radius: 12px!important;
  border: solid 2px rgb(var(--app-lightGrey))!important;
}

.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  border-color: rgb(var(--app-lightGrey)) !important;
}

.ng-select.ng-select-opened.ng-select-bottom>.ng-select-container {
  border-bottom-right-radius: 12px!important;
  border-bottom-left-radius: 12px!important;
}

.ng-option {
  margin-top: 1px;
  margin-bottom: 1px;
  color: rgb(var(--app-greyFont)) !important;
}

.ng-option:hover {
  background-color: rgb(var(--app-primaryColor))!important;
  color: rgb(var(--app-whitish))!important;
}

.ng-option-selected {
  background-color: rgb(var(--app-primaryColor))!important;
  color: rgb(var(--app-whitish))!important;
}

.ng-select.width-auto {
  display: flex;

  .ng-dropdown-panel {
    min-width: 196px;
    width: auto;
  }
}

.ng-dropdown-panel-items.scroll-host {
  margin-top: 8px;
  margin-bottom: 8px;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 7px;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    border-radius: 4px;
    background-color: rgb(var(--app-lightGrey));
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgb(var(--app-primaryColor));
  }
}


.ng-select.ng-select-opened>.ng-select-container {
  border: solid 2px rgb(var(--app-lightGrey))!important;
}
