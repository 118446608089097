app-loading-spinner {
  .mat-progress-spinner circle,
  .mat-spinner circle {
    stroke: rgb(var(--app-lightGrey));
  }
  .spinner-grow {
    color: rgb(var(--app-lightGrey));
  }
  &.app-loader-primay {
    .mat-progress-spinner circle,
    .mat-spinner circle {
      stroke: rgb(var(--app-primaryColor));
    }
    .spinner-grow {
      color: rgb(var(--app-primaryColor));
    }
  }
}
