.switch {
    display: inline-block;
    height: 42px;
    position: relative;
    vertical-align: middle;
    width: 68px;
    & input {
        display:none;
    }    
    & .slider {
        background-color: rgb(var(--app-greyFont));
        bottom: 0;
        cursor: pointer;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        transition: .4s;
        border: 6px solid transparent;
        &.dark-background {
            border-color: rgb(var(--app-whitish));
        }
        &.light-background {
            border-color: rgb(var(--app-lightGrey));
        }
    }    
    & .slider:before {
        background-color: rgb(var(--app-lightGrey));
        bottom: 2px;
        content: "";
        height: 26px;
        left: 2px;
        position: absolute;
        transition: .4s;
        width: 26px;
    }    
    & input:checked + .slider {
        background-color: rgb(var(--app-primaryColor));
    }    
    & input:checked + .slider:before {
        transform: translateX(26px);
    }    
    & .slider.round {
        border-radius: 34px;
    }    
    & .slider.round:before {
        border-radius: 50%;
    }
    &.switch-full {
        & .slider {
            background-color: rgb(var(--app-primaryColor));
            &:before {
                background-color: rgb(var(--app-whitish));
            }
        }
    }
} 