table > thead > tr > th {
  vertical-align: middle;
}

table {
  width: 100%;
}

table > thead > tr > th > a > fa-icon {
  font-size: $size-0-750;
  margin-left: 5px;
  color: rgba(var(--app-darkText), 0.8);
}