.btn:disabled, .btn.disabled, fieldset:disabled {
    opacity: 1;
    cursor: not-allowed;
    pointer-events: all;
}
.btn-app {
    font-family: $font-arial-bold;
    border-radius: 0.75rem;
    padding-left: 15px;
    padding-right: 15px;
    font-size: $size-button;
    &.app-primary {
        color: rgb(var(--app-lightGrey));
        background-color: rgb(var(--app-primaryColor));
        border-color: rgb(var(--app-primaryColor));
    }
    &.app-secondary {
        color: rgb(var(--app-lightGrey));
        background-color: rgb(var(--app-secondaryColor));
        border-color: rgb(var(--app-secondaryColor));
    }
    &.app-third {
        color: rgb(var(--app-darkText));
        background-color: rgb(var(--app-whitish));
        border-color: rgb(var(--app-whitish));
    }
}

.btn-app-withish {
    border-radius: 15px;
    color: rgb(var(--app-darkText));
    background-color: rgb(var(--app-whitish));
    border: none;
    font-family: $font-arial-bold;
    font-size: $size-1-5;
    &:hover, &:active {
        color: rgb(var(--app-darkText));
    }
}

.dual-button-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
}