.breathing {
    animation: breathing 2s linear infinite;
}
@keyframes breathing {
    0% {
        transform: scale(0.7);
    }
    25% {
        transform: scale(1);
    }
    80% {
        transform: scale(0.7);
    }
    100% {
        transform: scale(0.7);
    }
}

.blinking {
    animation: blinking 0.7s ease-in-out infinite alternate;
}
@keyframes blinking {
    0% {
        opacity: 1;
    }
    10% {
        opacity: 1;
    }
    20% {
        opacity: 0.9;
    }
    30% {
        opacity: 0.8;
    }
    40% {
        opacity: 0.6;
    }
    50% {
        opacity: 0.5;
    }
    60% {
        opacity: 0.4;
    }
    70% {
        opacity: 0.3;
    }
    80% {
        opacity: 0.2;
    }
    90% {
        opacity: 0.1;
    }
    100% {
        opacity: 0;
    }
}

.slide-up {
    animation: 0.4s slideUp cubic-bezier(1, -0.45, 0, 1.47);
}
@keyframes slideUp {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(-40px);
    }
}

.slide-down {
    animation: 0.4s slideDown cubic-bezier(1, -0.45, 0, 1.47);
}
@keyframes slideDown {
    from {
        transform: translateY(-100%);
    }
    to {
        transform: translateY(0);
    }
}
