@font-face {
  font-family: "Arial Light";
  src: url("/assets/fonts/ARIALLGT.woff") format("woff");
}

@font-face {
  font-family: "Arial Regular";
  src: url("/assets/fonts/ARIAL.woff") format("woff");
}

@font-face {
  font-family: "Arial Bold";
  src: url("/assets/fonts/ARIALBD.woff") format("woff");
}
