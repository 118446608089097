.modal.custom-app-modal {
    font-family: $font-arial-regular;
    & .modal-title {
        font-family: $font-arial-bold;
        font-size: 2rem;
        width: 100%;
        text-align: center;
    }
    & .modal-body {
        padding-left: 10px;
        padding-right: 10px;
    }
    & .modal-content {
        background: rgb(var(--app-whitish));
        color: rgb(var(--app-darkText));
        padding-top: 3rem;
        padding-bottom: 2rem;
        padding-left: 8%;
        padding-right: 8%;
        box-shadow: var(--card-shadow);
        border-radius: 2rem;
        & .dual-button-container {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 15px;
        } 
        &>.modal-header {
            padding-left: 0;
            padding-right: 0;
            border: none;
            & button.btn-close {
                color: rgb(var(--app-darkText));
            }
        }
    }
}
