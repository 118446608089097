// fonts variable
$font-arial-light: 'Arial Light';
$font-arial-regular: 'Arial Regular';
$font-arial-bold: 'Arial Bold';

//font size list
$size-0-5: 0.5rem; //8px
$size-0-625: 0.625rem; //10px
$size-0-750: 0.75rem; //12px
$size-0-8125: 0.8125rem; //13px
$size-0-875: 0.875rem; //14px
$size-1: 1rem; //16px
$size-1-125: 1.125rem; //18px
$size-1-250: 1.25rem; //20px
$size-1-375: 1.375rem; //22px
$size-1-5: 1.5rem; //24px
$size-1-625: 1.625rem; //26px
$size-1-750: 1.75rem; //28px
$size-1-875: 1.875rem; //30px
$size-2: 2rem; //32px
$size-2-125: 2.125rem; //34px
$size-2-250: 2.25rem; //36px
$size-2-375: 2.375rem; //38px
$size-2-5: 2.5rem; //40px
$size-2-625: 2.625rem; //42px
$size-2-750: 2.75rem; //44px
$size-2-875: 2.875rem; //46px
$size-3: 3rem; //48px
$size-3-125: 3.125rem; //50px
$size-3-250: 3.25rem; //52px
$size-3-375: 3.375rem; //54px
$size-3-5: 3.5rem; //56px
$size-3-625: 3.625rem; //58px
$size-3-750: 3.75rem; //60px
$size-3-875: 3.875rem; //62px
$size-4: 4rem; //64px
$size-4-125: 4.125rem; //66px
$size-4-250: 4.25rem; //68px
$size-4-375: 4.375rem; //70px
$size-4-5: 4.5rem; //72px
$size-4-625: 4.625rem; //74px
$size-4-750: 4.75rem; //76px
$size-4-875: 4.875rem; //78px
$size-5: 5rem; //80px

//font size
$size-title: 1.125rem; //18px
$size-h1: $size-2;
$size-h2: $size-1-750;
$size-h3: $size-1-5;
$size-h4: $size-1-250;
$size-h5: $size-1;
$size-h6: $size-1;
$size-p: $size-1;
$size-a: $size-1;
$size-input: $size-1;
$size-logo: $size-1;
$size-picto: $size-1;
$size-button: $size-1;
