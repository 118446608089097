.word-line-container {
  margin: 30px auto;
  // margin-top: 30px;
  // margin-bottom: 30px;
  color: rgb(var(--app-greyFont));
  width: 80%;
  & h1 {
    font-size: $size-h1;
    font-family: $font-arial-bold;
    &.word-line {
      width: 100%;
      text-align: center;
      border-bottom: 2px solid rgb(var(--app-primaryColor));
      line-height: 0.1rem;
      margin: 15px 0 20px 0;
      & > span {
        background: rgb(var(--app-whitish));
        padding: 0 20px;
      }
    }
  }
  &.sidebar {
    margin: auto;
    & h1.word-line {
      border-bottom: 1px solid rgb(var(--app-whitish));
    }
  }
}
