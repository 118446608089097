.cdk-overlay-pane {
    &.cdk-overlay-pane-custom-matpicker {
        .mat-datepicker-content {
            border-radius: 1.5rem;
            background-color: rgb(var(--app-whitish));
            color: rgb(var(--app-darkText));
            .mat-calendar {
                width: 14.5em;
                font-family: $font-arial-regular;
                td.mat-calendar-body-disabled {
                    opacity: 0.5;
                }
            }
            .mat-button,
            .mat-raised-button,
            .mat-icon-button,
            .mat-stroked-button,
            .mat-flat-button,
            .mat-fab,
            .mat-mini-fab {
                font-family: $font-arial-regular;
            }
            .time-container table.table {
                th,
                td {
                    padding: 0;
                    vertical-align: middle;
                    border: none !important;
                }
                tr:nth-child(2) {
                td:nth-child(2) {
                    font-size: 17px;
                    font-weight: bold;
                    font-family: $font-arial-bold;
                }
                }
            }
            .time-container {
                .mat-form-field-wrapper {
                    padding-bottom: 0px;
                }
                .mat-form-field-infix {
                    border: 1px solid;
                    margin: 0 -5px;
                    border-color: rgb(var(--app-secondaryColor));
                    background-color: rgb(var(--app-whitish));
                    color: rgb(var(--app-darkText));
                    border-radius: 50%;
                }
                .mat-form-field-underline {
                    background-color: transparent !important;
                    > .mat-form-field-ripple {
                        background-color: transparent;
                    }
                }
            }
            .ngx-mat-timepicker {
                margin-left: 30px;
                max-height: 90px;
            }
            .ngx-mat-timepicker-spacer {
                color: rgb(var(--app-whitish));
            }
            .mat-button.mat-primary,
            .mat-icon-button.mat-primary,
            .mat-stroked-button.mat-primary {
                color: rgb(var(--app-primaryColor));
            }
            .mat-button:hover,
            .mat-icon-button:hover,
            .mat-stroked-button:hover {
                color: rgb(var(--app-primaryColor)) !important;
            }
            .mat-calendar-body-today:not(.mat-calendar-body-selected) {
                border-color: rgb(var(--app-primaryColor));
            }
            .mat-calendar-body-selected {
                background-color: rgb(var(--app-primaryColor));
                color: rgb(var(--app-whitish)) !important;
            }
            .mat-calendar-period-button:hover {
                .mat-calendar-arrow {
                    border-top-color: rgb(var(--app-primaryColor)) !important;
                }
            }
            .actions {
                border-top: 1px solid transparent;
                border-top-color: rgb(var(--app-secondaryColor));
                .mat-button.mat-stroked-button.mat-button-base {
                    width: 100%;
                    margin-top: 5px;
                    border: unset;    
                    font-family: $font-arial-bold;
                    border-radius: 0.75rem;
                    color: rgb(var(--app-lightGrey));
                    background-color: rgb(var(--app-primaryColor));
                    &:hover {
                        color: rgb(var(--app-lightGrey)) !important;
                    }
                }
            }
            .mat-calendar-content,
            .mat-calendar-header,
            .mat-calendar-next-button,
            .mat-calendar-previous-button,
            .mat-calendar-table-header,
            .mat-calendar-body-label,
            .mat-calendar-body-cell-content,
            .time-container table {
                color: rgb(var(--app-darkText));
            }
            .mat-calendar-arrow {
                border-top-color: rgb(var(--app-secondaryColor));
            }
            .mat-form-field-underline,
            .mat-calendar-table-header-divider::after,
            .time-container::before {
                background-color: rgb(var(--app-secondaryColor));
            }
        }
    }
}