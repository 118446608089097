.pointer {
  cursor: pointer;
}

.disabled-cursor {
  cursor: not-allowed;
}

.is-light {
  font-family: $font-arial-light;
}
.is-regular {
  font-family: $font-arial-regular;
}
.is-italic {
  font-family: $font-arial-regular;
  font-style: italic;
}
.is-semi-bold {
  font-family: $font-arial-bold;
}
.is-bold {
  font-family: $font-arial-bold;
}
.is-weight-bold {
  font-weight: bold;
}
.is-lower-case {
  text-transform: lowercase;
}
.is-upper-case {
  text-transform: uppercase;
}
.is-capitalize {
  text-transform: capitalize;
}
.fit-content {
  width: fit-content;
  width: -moz-fit-content;
}
.is-hidden {
  visibility: hidden;
}
.is-underline {
  text-decoration: underline;
}

.align-center {
  text-align: center;
}

.is-primary {
  color: rgb(var(--app-primaryColor));
}

.is-secondary {
  color: rgb(var(--app-secondaryColor));
}

.is-third {
  color: rgb(var(--app-thirdColor));
}

.is-light-grey {
  color: rgb(var(--app-lightGrey));
}

.is-whitish {
  color: rgb(var(--app-whitish));
}

.is-valid {
  color: rgb(var(--app-green));
}

.is-invalid {
  color: rgb(var(--app-red));
}

.width-100 {
  width: 100%;
}

.width-45 {
  width: 45%;
}

.is-grey-font {
  color: rgb(var(--app-greyFont));
}

.is-small-text {
  font-size: $size-0-875;
}

.expires-at-15 {
  background-color: rgb(var(--app-red));
}

.expires-at-15-txt {
  color: rgb(var(--app-red));
}

.expires-at-30 {
  background-color: rgb(var(--app-orange));
}

.expires-at-30-txt {
  color: rgb(var(--app-orange));
}

.is-expired {
  background-color: rgb(var(--app-grey));
}

.is-expired-txt {
  color: rgb(var(--app-grey));
}

.line-txt {
  text-decoration: line-through;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-right-20 {
  margin-right: 20px;
}

.margin-left-20 {
  margin-left: 20px;
}

h1 {
  font-size: 2rem;
  font-family: $font-arial-bold;
}

img {
  image-rendering: -webkit-optimize-contrast;
}

.is-text-shadow {
    text-shadow: var(--text-shadow);
}

.is-button-shadow {
    box-shadow: var(--text-shadow);
}

fa-icon.is-icon-shadow {
    svg {
        filter: drop-shadow(var(--text-shadow));
    }
}

img.is-icon-shadow {
    filter: drop-shadow(var(--text-shadow));
}

.solo-item-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
