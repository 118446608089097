@mixin virtuvisio-app-theme {
  // colors variable
  --app-primaryColor: 189, 49, 86;
  --app-secondaryColor: 40, 54, 58;
  --app-thirdColor: 255, 100, 107;
  --app-darkText: 40, 54, 58;
  --app-lightText: 241, 243, 244;
  --app-greyText: 83, 94, 97;
  --app-lightGrey: 219, 218, 218;
  --app-whitish: 241, 243, 244;
  --app-greyFont: 83, 94, 97;
  --app-linearGradient: linear-gradient(90deg, rgb(189, 49, 86) 0%, rgb(40, 54, 58) 100%);
  --app-linearGradient-50: linear-gradient(90deg, rgb(241, 243, 244) 50%, rgb(40, 54, 58) 50%);
  --app-lightGreyBackground: 227, 227, 227;
  --app-green: 100, 167, 99;
  --app-red: 255, 0, 0;
  --app-orange: 255, 165, 0;
  --app-grey: 128, 128, 128;

  // shadow variable
  --card-shadow: 6px 15px 10px 5px rgba(0, 0, 0, 0.25);
  --input-shadow: 0px 4px 4px 0px rgba(40, 54, 58, 0.5);
  --button-shadow: 0px 4px 4px 0px rgba(40, 54, 58, 0.5);
  --dropdown-shadow: 0px 4px 4px 0px rgba(40, 54, 58, 0.5);
  --text-shadow: 4px 3px 3px rgba(40, 54, 58, 0.3);
  --text-shadow-white: 4px 4px 3px rgba(40, 54, 58, 0.4);
}

/*
 * old variables
 */
// colors variable
// $virtuvisio-raspberry: #bd3156;
// $virtuvisio-raspberry-80: #ca5a78;
// $virtuvisio-coal: #28363a;
// $virtuvisio-coal-80: #535e61;
// $virtuvisio-light-grey: #dbdada;
// $virtuvisio-light-grey-background: #E3E3E3;
// $virtuvisio-whitish: #f1f3f4;
// $virtuvisio-grey-font: $virtuvisio-coal-80;
// $virtuvisio-coral: #ff646b;
// $virtuvisio-coral-80: #ff8389;
// $virtuvisio-green: #64A763;
// $linear-gradient: linear-gradient(90deg, $virtuvisio-raspberry 0%, $virtuvisio-coal 100%);
// $linear-gradient-50: linear-gradient(
//   90deg,
//   $virtuvisio-whitish 50%,
//   $virtuvisio-coal 50%
// );
// $red: #ff0000;
// $orange: #ffa500;
// $grey: #808080;

// shadow variable
// $card-shadow: 6px 15px 10px 5px rgba(0, 0, 0, 0.25);
// $input-shadow: 0px 4px 4px 0px rgba($virtuvisio-coal, 0.5);
// $button-shadow: 0px 4px 4px 0px rgba($virtuvisio-coal, 0.5);
// $dropdown-shadow: 0px 4px 4px 0px rgba($virtuvisio-coal, 0.5);
// $text-shadow: 4px 3px 3px rgba($virtuvisio-coal-80, 0.5);
// $text-shadow-white: 4px 4px 3px rgba($virtuvisio-coal-80, 0.6);