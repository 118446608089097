/* You can add global styles to this file, and also import other style files */
@import 'assets/scss/define/fonts';
@import 'assets/scss/define/variables';
@import "assets/scss/define/icons";
@import 'assets/scss/style/style';
@import 'assets/scss/style/_switch-toggle';
@import 'assets/scss/style/buttons';
@import 'assets/scss/style/forms';
@import 'assets/scss/style/modal';
@import 'assets/scss/style/notifications';
@import 'assets/scss/style/custom-datepicker';
@import 'assets/scss/style/scrollbar';
@import 'assets/scss/style/tooltip';
@import 'assets/scss/style/word-line';
@import 'assets/scss/style/animations';
@import 'assets/scss/style/loader';
@import 'assets/scss/style/tables';
@import 'assets/scss/style/mat-tabs-group';
@import 'assets/scss/style/accordion';
@import 'assets/scss/style/ng-select';
@import "assets/scss/style/material-icons";

@import "assets/scss/theme/virtuvisio";

:root {
  .virtuvisio {
    @include virtuvisio-app-theme;
  }
}

html,
body {
  overflow-x: hidden;
}
html {
  height: 100%;
}
body {
  min-height: 100%;
  margin: 0;
  font-family: 'Arial Regular', 'Arial Light', 'Arial Bold';
  color: rgb(var(--app-greyFont));
  background-color: rgb(var(--app-lightGreyBackground));
  & .home-loader.loader-app {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      width: 120px;
      margin-bottom: 20px;
    }
    .dot-pulse {
      display: inline-flex;
      // dots animation
      & p {
        margin: 20px;
        transition: all 2s linear;
      }
      & .dot1,
      & .dot2,
      & .dot3 {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: rgba(var(--app-primaryColor), 0.5);
      }
      & .dot1 {
        animation: scaleUpRaspberry 1000ms infinite alternate ease-in-out;
        animation-delay: 0ms;
      }
      & .dot2 {
        animation: scaleUpRaspberry 1000ms infinite alternate ease-in-out;
        animation-delay: 250ms;
      }
      & .dot3 {
        animation: scaleUpRaspberry 1000ms infinite alternate ease-in-out;
        animation-delay: 500ms;
      }
      & .dot1,
      & .dot3 {
        // end of the dots
        &.endingDots {
          margin: 1000px;
          transition: all 2s linear;
        }
      }
    }
  }

  // dots animation
  @keyframes scaleUpRaspberry {
    0% {
      transform: scale(1);
      animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }
    50% {
      transform: scale(1);
      animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }
    100% {
      transform: scale(1.5);
      background-color: rgb(var(--app-primaryColor));
      animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }
  }
}
