.accordion {
  & .accordion-item {
    background-color: rgb(var(--app-whitish));
    border-radius: 12px;
    & :last-of-type .accordion-button.collapsed {
      border-radius: 12px;
    }
    & :first-of-type .accordion-button {
      border-radius: 12px 12px 2px 2px;
    }

    & .accordion-button {
      background-color: rgb(var(--app-whitish));
      color: rgb(var(--app-darkText));
      font-family: $font-arial-regular;

      &:focus {
        border-color: rgb(var(--app-thirdColor));
      }

      &:hover, &:not(.collapsed) {
        color: rgb(var(--app-primaryColor));

        &::after {
          background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23bd3156'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>")
        }
      }
    }
  }
}

.stacked {
  & .accordion-item {
    background-color: rgb(var(--app-whitish));
    border-radius: 2px;

    &:last-child {
      border-radius: 2px 2px 12px 12px;
    }

    &:first-child {
      border-radius: 12px 12px 2px 2px;
    }
  }
}
